
.news-headline {
  padding-top: 40px;
}

.newsletter {
  position: relative;

  padding: 0 25px;
  padding-bottom: 25px;
  @media (max-width: $screen-xs-max) {
    padding: 0 13px;
    padding-bottom: 25px;
  }

  .about {
    padding-bottom: 25px;
  }

  .form {
    .input {
      margin-bottom: 10px;
    }
  }
}

.section-media {
  margin-bottom: 25px;
  img {
    max-width: 100%;
    min-width: 100%;
  }
}

.article {
  .news-backlink {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}


.articles {
  padding: 25px;
  @media (max-width: $screen-xs-max) {
    padding: 13px;
  }

  .item {
    position: relative;
    background: #eee;
    margin-bottom: 35px;

    @media (max-width: $screen-sm-max) {
      margin-bottom: 20px;
    }

    .row {
      height: 100%;
    }

    .image {
      overflow: hidden;
      height: 100%;

      .news-image {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      a {
        height: 100%;
        display: block;
      }

      img {
        display: inline-block;
        max-width: 100%;
        min-width: 100%;
      }

      @media (max-width: $screen-sm-max) {
        height: 150px;
      }
    }

    .text {
      position: relative;
      height: 100%;

      @media (max-width: $screen-sm-max) {
        height: auto;
      }

      .headline {
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 20px;
        text-transform: uppercase;
        color: #000;
        a { color: #000; text-decoration: none }
        @media (max-width: $screen-sm-max) {
          margin-top: 13px;
        }
      }

      .more {
        font-weight: bold;
      }

      .content {
        padding: 15px;
        padding-bottom: 35px;
      }

      .footer {
        text-align: right;
        position: absolute;
        right: 30px;
        bottom: 15px;
        margin: 0;
        a {
          font-weight: bold;
        }
      }
    }
  }
}

.news-single {
  padding-top: 25px;

  .article {
    .news-img-wrap {
      width: auto;
      max-width: 100%;
      margin: 0;
      float: none;
      background: transparent;

      iframe {
        width: 100% !important;
      }
    }
    .news-img-wrap .viewer a {
      float: none;
      padding: 0;
      border: none;
    }

    .image {
      .viewer {
        text-align: center;

        img {
          margin: 0 auto;
          display: inline-block;

          @media (max-width: $screen-xs-max) {
            width: 100%;
          }
        }

        .item {
          padding: 0px;
          a {
            border: none;
            padding: 0;
          }
        }

        .carousel-indicators {
          bottom: -60px;
          li {
            border-color: #DEDEDE;
            background: #DEDEDE;
          }
          .active {
            background: #8D8D8D;
          }

          @media (max-width: $screen-sm-max) {
            bottom: 8px;
          }

          @media (min-width: $screen-sm-min) {
            display: none;
          }
        }

      }

      .images {
        padding-top: 15px;
        @include clearfix();
      }
      .badges {
        position: relative;
        padding-top: 10px;
      }

    }

    .thumbnails {
      position: relative;
      .thumbnail {
        float: left;
        cursor: pointer;
        border-color: #333;
        background: #000;
        padding: 5px;
        display: block;

        height: 80px;
        width: 33.333%;
        margin: 0;


        &:last-of-type {
          margin-right: 0;
        }

        img {
          max-height: 100%;
          position: relative;
          top: 50%;
          transform: translateY(-50%)
        }

        .glyphicon-plus {
          margin-top: 21px;
          margin-left: 22px;
        }
      }
      .active {
        border-color: $brand-primary;
      }
    }

  }
}

.modal-close {
  position: absolute;
  z-index: 1;
  right: 15px;
  top: 14px;
}
.news-banner {
  position: relative;
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
}

.news-article-text {
  img {
    width: 100% !important;
    height: auto !important;
  }

  column-count: 2;
  column-gap: 25px;

  @media (max-width: $screen-xs-max) {
    column-count: 1;
  }
}
