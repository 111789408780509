$hp-spacer: 20px;
$hp-title-size: 36px;
$hp-block-size: 324px;
$hp-block-top: 145px;

.hp {
  position: relative;
}

.homepage #slider {
  margin-bottom: $hp-spacer !important;
}

// Container Split
// --------------------------------------------

.hp-split {
  width: 100%;
  display: flex;
  flex-flow: row;
}

.hp-split-col {
  display: block;
}

.hp-split-left {
  width: 50%;;
  padding-left: $hp-spacer;
  padding-right: $hp-spacer;
}
.hp-split-right {
  width: 50%;
  background: #000;
  padding: 15px $hp-spacer;
}

// Banner Figures (left)
// --------------------------------------------

.hp-banner {
  height: $hp-block-size;
  background: #000;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: $hp-spacer;

  img {
    opacity: 1;
    transition: opacity 350ms;
    min-width: 100%;
    min-height: 100%;
    max-width: initial;
  }

  figcaption {
    position: absolute;
    display: block;
    z-index: 10;
    top: $hp-block-top;
    left: 0;
    right: 0;
    text-align: center;

    span {
      display: inline-block;
      background: #000;
      color: rgb(255, 255, 255);
      font-weight: bold;
      text-transform: uppercase;
      padding: 10px 20px;
      line-height: 1;
      font-size: $hp-title-size;
      letter-spacing: 0.04em;
      cursor: pointer;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    display: block;
    text-decoration: none;
  }

  &:hover {
    figcaption span {
      background: rgb(84, 172, 145);
    }
    img {
      opacity: 0.8;
    }
  }
}

// Socialmedia Posts (right)
// --------------------------------------------

.hp-socialmedia {
  display: flex;
  flex-flow: row wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.hp-socialmedia .socialmedia-article {
  width: calc(50% - 20px);
}

.hp-social-header {
  text-align: center;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 22px;

  .hp-social-headline,
  .hp-social-headline a {
    color: #fff;
    font-size: $hp-title-size;
    font-weight: bold;
    text-transform: uppercase;
  }

  .hp-social-headline a {
    padding: 7px 20px;
    text-decoration: none;
  }

  .hp-social-headline a:hover {
    color: #fff;
    background-color: #1cbe95;
  }


  .hp-social-tagline {
    font-size: 14px;
    color: #777;
  }
}

.hp-social-footer {
  text-align: center;
  padding: 10px 0;

  a {
    color: #1cbe95;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
  }
}

.hp-s {

  // Wrap
  // --------------------------------------------

  @media (max-width: 1199px) {

    .hp-split {
      width: 100%;
      flex-flow: row wrap;
    }

    .hp-split-col {
      flex-grow: 1;
    }

    .hp-split-left {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      margin: 0 -10px;
    }

    .hp-banner {
      display: block;
      width: calc(33.333% - 20px);
      margin: 0 10px;
      height: 260px;
      margin-bottom: 20px;
    }
    .hp-banner figcaption {
      top: 118px;
    }
    .hp-banner figcaption span {
      font-size: 20px;
    }

    .hp-split-right {
      width: 100%;
    }
  }
  @media (max-width: 767px) {

    .hp-split-left {
      flex-flow: column wrap;
      margin-bottom: 15px;
    }
    .hp-banner {
      display: block;
      width: 100%;
      height: 100px;
      margin: 0;
      margin-bottom: 15px;
    }
    .hp-banner figcaption {
      top: 30px;
    }

  }

  @media (max-width: 467px) {
    .hp-socialmedia .socialmedia-article {
      width: calc(100% - 20px);
    }
  }

}