.gewinnspiel {

  position: relative;
  padding: 25px 0;

  padding-top: 0;

  .text.text-block {
    padding-bottom: 25px;
  }

  select {
    width: auto !important;
  }

  .aboutme {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;

    select {
      display: inline;
    }
  }

  .input {
    padding-bottom: 15px;
  }

  .checkbox input[type=checkbox] {
    margin-left: 0;
  }

  .closing-text {
    padding: 20px 0;
  }

  @media (min-width: $screen-md-min) {
    .wrapper {
      max-width: 68%;
    }
  }

  @media (max-width: $screen-xs-max) {
    .closing-text {
      padding: 15px 0;
    }
    .aboutme span {
      display: block;
    }
    .aboutme span:last-of-type {
      padding-top: 10px;
    }
  }

}

#page-255, #page-273 {

  .carousel {
    border-bottom: 12px solid rgb(19, 193, 153);

    &:after {
      content: " ";
      background: url('/design/img/stageline/aktion/moopmama.png');
      height: 140px;
      width: 240px;
      position: absolute;
      display: block;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-color: rgb(255, 255, 255);

      top: 39vw;
      right: 10vw;

      @media (min-width: 1400px) {
        top: 548px;
        right: 140px;
      }
      @media (max-width: 1100px) {
        right: 5vw;
      }
      @media (max-width: 1000px) {
        display: none;
      }
    }


    .slide {
      position: relative;
      overflow: hidden;
    }
    .slide img {
      min-width: 100%;
    }
    .slide a {
      height: 35vw;
      display: block;
      overflow: hidden;
      max-height: 480px;
    }
    .watermark {
      display: none;
    }
    .button {
      top: 25px;
      right: 25px;
      @media (max-width: $screen-xs-max) {
        top: 13px;
        right: 13px;
      }
    }
  }
  .carousel-indicators  {
    @media (max-width: $screen-xs-max) {
      margin-bottom: 0;
      bottom: 0px;
    }
  }
  .carousel-indicators li {
    margin: 5px;
  }
  .carousel-indicators li.active {
    margin: 4px;
  }
}