
#element-2299, #element-2302 {
  background: #efefef;
  padding-top: 25px;

  .ce-gallery {
    margin-bottom: 0;
  }
  .ce-row, .ce-textpic, .ce-gallery {
    overflow: visible;
  }
  img {
    transition: transform 0.2s ease;
    transform: scale(1);
  }
  a:hover img {
    transform: scale(1.1);
  }

  @media (max-width: 420px) {
    .ce-row {
      width: 100%;
    }
    .ce-column {
      width: 47%;
      float: left;
      min-height: 250px
    }
  }
}


.supporter {
  padding-top: 25px;

  .support-title {
    padding-left: 25px;
    @media (max-width: $screen-xs-max) {
      font-size: 18px;
      padding-left: 15px;
    }
  }

  .support {
    color: #fff;
    background: #000;
    margin-bottom: 25px;
    position: relative;

    .row {
      @media (min-width: $screen-sm-min) {
        display: table-row;
        .col-xs-6 {
          display: table-cell;
          float: none !important;
        }
      }
      @media (max-width: $screen-xs-max) {
        .eq-element {
          height: auto !important;
        }
      }
    }

    .support-contact-form {
      background: #747474;
    }

    .contact-card {
      display: flex;
      margin-left: -15px;
      min-height: 147px;

      @media (max-width: $screen-xs-max) {
        display: block;
        margin: 0
      }
    }

    .contact-image {
      @media (max-width: $screen-xs-max) {
        img { min-width: 100%; }
      }
    }

    .contact-cell {
      @media (min-width: $screen-sm-min) {
        flex-grow: 1;
        vertical-align: top;
      }
    }

    .contact-info {
      background: #747474;
      padding: 20px;
      min-width: 50%;
      transition: margin 300ms ease-out;
      @media (max-width: $screen-sm-max) {
        margin-right: -15px;
      }
      @media (max-width: $screen-xs-max) {
        font-size: 13px;
        padding: 5px 13px
      }
      .name {
        text-transform: uppercase;
        font-weight: 700;
      }
      li {
        padding-top: 10px;
        white-space: nowrap;
      }
      a {
        color: #fff;
      }

      &:hover {
        margin-top: -10px;
        background: $teal;
        color: #fff;
      }
    }

    .about {
      height: 100%;
      background: #545454;
      font-size: 13px;
      line-height: 1.5;

      margin-left: -25px;
      margin-right: -25px;

      padding: 25px 50px;

      @media (max-width: $screen-xs-max) {
        padding: 13px;
        margin: 0;
      }
    }

    @media (max-width: $screen-xs-max) {
      .contact-info {
        transition: none;
      }
      .contact-info:hover {
        margin-top: 0;
        background: #747474;
      }
    }

    .contact-card.no-hover {
      .contact-info {
        transition: none;
      }
      .contact-info:hover {
        margin-top: 0;
        background: #747474;
      }
    }
  }
}

.printorder {
  .printorder-title {
    padding-left: 25px;
  }
}

