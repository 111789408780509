/*
	Table of Contents
	update on Thu Mar 17 2016 10:21:48 GMT+0200 (FLE Standard Time)

	I.   Generic --------------------------------------
	     01. Reset ------------------------------------
	     02. Base -------------------------------------
	     03. Fonts ------------------------------------
	     04. Helpers ----------------------------------
	         Clear ------------------------------------
	         Notext -----------------------------------
	         Hidden -----------------------------------
	         Alignleft --------------------------------
	         Alignright -------------------------------
	         Disabled ---------------------------------
	II.  Regions --------------------------------------
	     01. Container --------------------------------
	     02. Content ----------------------------------
	     03. Footer -----------------------------------
	     04. Header -----------------------------------
	     05. Main -------------------------------------
	     06. Shell ------------------------------------
	     07. Sidebar ----------------------------------
	     08. Wrapper ----------------------------------
	III. Modules --------------------------------------
	     01. Button -----------------------------------
	         Button Block -----------------------------
	     02. Form Elements ----------------------------
	     03. Form -------------------------------------
	     04. List -------------------------------------
	     05. Logo -------------------------------------
	     06. Nav --------------------------------------
	     07. Section ----------------------------------
	     08. Section Top Right ------------------------
	     09. Section Top Left -------------------------
	     10. Section Bottom Right ---------------------
	     11. Section Bottom Left ----------------------
	     12. Slider -----------------------------------
	     13. Table ------------------------------------
	     14. Widget -----------------------------------
 */

.ico-arrow-down { background-image: url(/design/img/stageline/sprite.png); background-position: 0 0; width: 14px; height: 9px; display: inline-block; vertical-align: middle; font-size: 0; }

/* ------------------------------------------------------------ *\
	I.   Generic
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */


/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */

.clear:after { content: ''; line-height: 0; display: table; clear: both; }


/*  Notext  */

.notext { white-space: nowrap; text-indent: 100%; text-shadow: none; overflow: hidden; }
.notext-old { font-size: 0; line-height: 0; text-indent: -4000px; }


/*  Hidden  */

[hidden],
.hidden { display: none; }


/*  Alignleft  */

.alignleft { float: left; }


/*  Alignright  */

.alignright { float: right; }


/*  Disabled  */

[disabled],
.disabled { cursor: default; }

.shell { padding: 25px; margin: auto; }
@media (max-width: 767px) {
  .shell { padding: 15px; margin: auto; }
}


.btn { display: inline-block; vertical-align: middle; -webkit-appearance: none; -moz-appearance: none; appearance: none; }


/*  Button Block  */
.btn-block { display: block; padding-left: 0; padding-right: 0; }
.btn-circle { width:32px; height: 32px; border-radius: 50%; background:#30c89e; color: #fff; text-align:center; line-height:32px; display: inline-block; }


/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */
.section .placeholder img { display:block; width:100%; }

.section .expand-area { display:none; }
.section .expand-trigger { color: #30c89e; text-decoration: none; font-weight:800; font-size:16px; letter-spacing: 1px; }

.section { position: relative; font-size:20px; color: #fff; overflow: hidden; }
.section .section-image { position: absolute; top:0; bottom:0; left:0; width:100%; background: url(/design/img/stageline/section-image.jpg) no-repeat 0 0;
  background-repeat: no-repeat; background-position:50% 50%; background-size: cover; }
.section .section-inner { position: relative; z-index: 2; transition: opacity 2s; opacity: 0; }
.section.animate .section-inner { opacity: 1; }

.section .section-circle { z-index: 1; position: absolute; width:975px; height: 975px; border-radius: 50%; background: rgba(0,0,0, .5); }
.section .section-body { position: absolute; transition: all .6s; opacity: 0; }
.section.animate .section-body { opacity:1; }

.section .section-circle:before { content:''; position: absolute; top:-144px; left:-144px; bottom:-144px; right:-144px; border: 144px solid rgba(255,255,255, .5); border-radius: 50%; }
.section .section-circle:after { transition: all 2s; content:''; position: absolute; top:-304px; left:-304px; bottom:-304px; right:-304px; border-radius: 50%; border-right: 25px solid transparent; border-top: 25px solid transparent; border-left: 25px solid rgba(255,255,255, .5); border-bottom: 25px solid rgba(255,255,255, .5); }

.section .section-wrapper { position: relative; min-height: 723px; overflow: hidden; }
.section .section-head { background:#fff; text-align:right; padding: 26px 40px 90px 40px; }
.section .section-content { width:430px; padding-bottom:0; transition: all .3s; max-height: 0; overflow: hidden; }
.section .section-content.open { max-height: 300px; padding-bottom:54px; }

.section .section-actions a { color: #30c89e; text-decoration: none; font-weight:800; font-size:16px; }

.section .section-title { font-size:50px; font-weight:800; text-transform: uppercase; color: #fff; line-height:1.1; margin-bottom:54px; }
.section .section-title small { display:block; font-size:50%; }


/* ------------------------------------------------------------ *\
	Section Top Right
\* ------------------------------------------------------------ */

.section.section-top-right .section-body { right:-100%; top:50px; z-index: 5; width:606px; }
.section.animate.section-top-right .section-body { right:0; }
.section.section-top-right .section-circle { left:-202px; top:auto; bottom:-200px; }

.section.section-top-right .section-circle:after { -webkit-transform:rotate(-90deg); -ms-transform:rotate(-90deg); transform:rotate(-90deg); }
.section.animate.section-top-right .section-circle:after { -webkit-transform:rotate(-45deg); -ms-transform:rotate(-45deg); transform:rotate(-45deg); }

/* ------------------------------------------------------------ *\
	Section Top Left
\* ------------------------------------------------------------ */

.section.section-top-left .section-body { left:-100%; top:50px; z-index: 5; width:670px; }
.section.animate.section-top-left .section-body { left:40px; }
.section.section-top-left .section-circle { left:auto; top:auto; bottom:-240px; right:0; }

.section.section-top-left .section-circle:after { -webkit-transform:rotate(45deg); -ms-transform:rotate(45deg); transform:rotate(45deg); }
.section.animate.section-top-left .section-circle:after { -webkit-transform:rotate(-45deg); -ms-transform:rotate(-45deg); transform:rotate(-45deg); }

/* ------------------------------------------------------------ *\
	Section Bottom Right
\* ------------------------------------------------------------ */

.section.section-bottom-right .section-body { right:-100%; bottom:50px; z-index: 5; width:580px; }
.section.animate.section-bottom-right .section-body { right:0; }
.section.section-bottom-right .section-circle { left:-238px; top:-263px; }

.section.section-bottom-right .section-circle:after { -webkit-transform:rotate(45deg); -ms-transform:rotate(45deg); transform:rotate(45deg); }
.section.animate.section-bottom-right .section-circle:after { -webkit-transform:rotate(-45deg); -ms-transform:rotate(-45deg); transform:rotate(-45deg); }


/* ------------------------------------------------------------ *\
	Section Bottom Left
\* ------------------------------------------------------------ */

.section.section-bottom-left .section-body { left:-100%; bottom:50px; z-index: 5; width:772px; }
.section.animate.section-bottom-left .section-body { left:50px; }
.section.section-bottom-left .section-circle { left:-238px; top:-268px; }

.section.section-bottom-left .section-circle:after { -webkit-transform:rotate(-135deg); -ms-transform:rotate(-135deg); transform:rotate(-135deg) }
.section.animate.section-bottom-left .section-circle:after { -webkit-transform:rotate(-45deg); -ms-transform:rotate(-45deg); transform:rotate(-45deg); }

.section.section-bottom-right .section-circle:after { border-right: 25px solid rgba(255,255,255, .5); border-top: 25px solid rgba(255,255,255, .5); border-left: 25px solid transparent; border-bottom: 25px solid transparent; }
.section.section-bottom-left .section-circle:after { border-right: 25px solid rgba(255,255,255, .5); border-top: 25px solid rgba(255,255,255, .5); border-left: 25px solid transparent; border-bottom: 25px solid transparent; }

@media (max-width: 767px) {
  .section .section-title { font-size:24px; margin-bottom:0; }
  .section .section-wrapper { min-height: 380px; }
  .section .expand-trigger { font-size:14px; }

  .section .section-circle { width:314px; height: 314px; }
  .section .section-circle:before { top:-47px; left:-47px; bottom:-47px; right:-47px; border: 47px solid rgba(255,255,255, .5); }
  .section .section-circle:after {
    top:-100px; left:-100px; bottom:-100px; right:-100px; border-right: 10px solid transparent; border-top: 10px solid transparent; border-left: 10px solid rgba(255,255,255, .5); border-bottom: 10px solid rgba(255,255,255, .5);
  }

  .section.animate.section-bottom-left .section-body { left:20px; bottom:20px; }
  .section.animate.section-bottom-right .section-body { right:25px; bottom:20px; }
  .section.animate.section-top-left .section-body { top:20px; left:20px;}

  .section.section-bottom-left .section-body,
  .section.section-bottom-right .section-body,
  .section.section-top-left .section-body,
  .section.section-top-right .section-body { width:260px; }

  .section .section-actions { display:none; }

  .section .expand-area { display:block; padding-bottom:40px; text-align:right; }
  .section .expand-area .expand-area-content {
    max-height: 0; overflow: hidden; text-align:left; font-size:16px;  color: #000; line-height:28px;
    /* transition: all .3s; */
  }
  .section .section-head.expanded .expand-area .expand-area-content { max-height: 300px; overflow: hidden; }

  .section .section-head { text-align:center; padding: 12px 20px 25px 20px; transition: all .3s; }
  .section .section-head.expanded { background:#f5f5f5; }

  .section.section-top-right .section-body { top:20px; }

  .section.section-top-right .section-circle { left:-44px; top:-123px; }
  .section.section-top-left .section-circle { left: -36px;top: -140px;bottom: auto;right: auto; }
  .section.section-bottom-left .section-circle { left: -43px; top: -87px; }
  .section.section-bottom-right .section-circle { left:-60px; top:-120px; bottom:auto; right:auto; }

  .section.section-bottom-right .section-circle:after,
  .section.section-bottom-left .section-circle:after { border-right-width:10px; border-top-width:10px; border-left-width:10px; border-bottom-width:10px; }

}

/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {}

.slider .slides { list-style: none outside none; }

/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */

.table {}

/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widgets { list-style: none outside none; }
