//
// Variables
// --------------------------------------------------

$teal: rgb(48,200,158);
$black: rgb(0,0,0);
$black80: lighten($black, 20%);
$black60: lighten($black80, 20%);
$black40: lighten($black60, 20%);
$black20: lighten($black40, 20%);

$primary: $teal;

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 20%);
$gray-dark:              lighten($gray-base, 40%);
$gray:                   lighten($gray-base, 60%);
$gray-light:             lighten($gray-base, 80%);
$gray-lighter:           lighten($gray-base, 90%);

$brand-primary:         $teal; // #337ab7

$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff;
//** Global text color on `<body>`.
$text-color:            #000;

//** Global textual link color.
$link-color:            $brand-primary;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%);
//** Link hover decoration.
$link-hover-decoration: underline;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "CeraPRO-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-base:          15px;
$font-size-large:         20px;
$font-size-small:         10px;

$font-size-h1:            35px;
$font-size-h2:            25px;
$font-size-h3:            $font-size-large;
$font-size-h4:            $font-size-large;
$font-size-h5:            $font-size-base;
$font-size-h6:            $font-size-base;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.3;

//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit;
$headings-font-weight:    500;
$headings-line-height:    1.1;
$headings-color:          inherit;


//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.
$icon-font-path:          "../fonts/";
//** File name for all font files.
$icon-font-name:          "glyphicons-halflings-regular";
//** Element ID within SVG icon file.
$icon-font-svg-id:        "glyphicons_halflingsregular";


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     5px;
$padding-base-horizontal:   10px;

$padding-large-vertical:    10px;
$padding-large-horizontal:  15px;

$padding-small-vertical:    5px;
$padding-small-horizontal:  10px;

$padding-xs-vertical:       1px;
$padding-xs-horizontal:     5px;

$line-height-large:         1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5;

$border-radius-base:        0px;
$border-radius-large:       0px;
$border-radius-small:       0px;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    #fff;

//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $brand-primary;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base:          4px;

//** Carets increase slightly in size for larger components.
$caret-width-large:         5px;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            5px;
$table-condensed-cell-padding:  5px;

//** Default background color used for all tables.
$table-bg:                      transparent;
//** Background color used for `.table-striped`.

$table-bg-accent:               #f9f9f9;
$table-bg-hover:                #f5f5f5;
$table-bg-active:               $table-bg-hover;
$table-border-color:            $black20;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                normal;

$btn-default-color:              #fff;
$btn-default-bg:                 #666666;
$btn-default-border:             #666666;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $brand-primary;

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             $brand-success;

$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info;
$btn-info-border:                $brand-info;

$btn-warning-color:              #fff;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             $brand-warning;

$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              $brand-danger;

$btn-link-disabled-color:        $gray-light;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base;
$btn-border-radius-large:        $border-radius-large;
$btn-border-radius-small:        $border-radius-small;


//== Forms
//
//##

//** `<input>` background color
$input-bg:                       #fff;
//** `<input disabled>` background color
$input-bg-disabled:              $gray-lighter;

//** Text color for `<input>`s
$input-color:                    $black;
//** `<input>` border color
$input-border:                   $black20;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius:            $border-radius-base;
//** Large `.form-control` border radius
$input-border-radius-large:      $border-radius-large;
//** Small `.form-control` border radius
$input-border-radius-small:      $border-radius-small;

//** Border color for inputs on focus
$input-border-focus:             #66afe9;

//** Placeholder text color
$input-color-placeholder:        #999;

//** Default `.form-control` height
$input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2);
//** Large `.form-control` height
$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2);
//** Small `.form-control` height
$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2);

//** `.form-group` margin
$form-group-margin-bottom:       15px;

$legend-color:                   $gray-dark;
$legend-border-color:            #e5e5e5;

//** Background color for textual input addons
$input-group-addon-bg:           $gray-lighter;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border;

//** Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed;


//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg:                    #fff;
//** Dropdown menu `border-color`.
$dropdown-border:                rgba(0,0,0,.15);
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border:       #ccc;
//** Divider color for between dropdown items.
$dropdown-divider-bg:            #e5e5e5;

//** Dropdown link text color.
$dropdown-link-color:            $gray-dark;
//** Hover color for dropdown links.
$dropdown-link-hover-color:      darken($gray-dark, 5%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         #f5f5f5;

//** Active dropdown menu item text color.
$dropdown-link-active-color:     $component-active-color;
//** Active dropdown menu item background color.
$dropdown-link-active-bg:        $component-active-bg;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color:   $gray-light;

//** Text color for headers within dropdown menus.
$dropdown-header-color:          $gray-light;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color:           #000;


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            1000;
$zindex-dropdown:          1000;
$zindex-popover:           1060;
$zindex-tooltip:           1070;
$zindex-navbar-fixed:      1030;
$zindex-modal-background:  1040;
$zindex-modal:             1050;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1280px;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:   12;

//** Vary gutter by screen size
$grid-gutter-width-xs:  13px;
$grid-gutter-width-sm:  25px;
$grid-gutter-width-md:  25px;
$grid-gutter-width-lg:  25px;

$grid-gutter-width:  $grid-gutter-width-md;


// Navbar collapse

//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     1200px;

//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);



//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width-sm);
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width-md);
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width-lg);
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop;


//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    50px;
$navbar-margin-bottom:             $line-height-computed;
$navbar-border-radius:             $border-radius-base;
$navbar-padding-horizontal:        floor(($grid-gutter-width / 2));
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2);
$navbar-collapse-max-height:       340px;

$navbar-default-color:             #000;
$navbar-default-bg:                transparent;
$navbar-default-border:            transparent;

// Navbar links
$navbar-default-link-color:                #000;
$navbar-default-link-hover-color:          #fff;
$navbar-default-link-hover-bg:             $teal;
$navbar-default-link-active-color:         #fff;
$navbar-default-link-active-bg:            $teal;
$navbar-default-link-disabled-color:       #ccc;
$navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color;
$navbar-default-brand-hover-color:         transparent;
$navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg:           transparent;
$navbar-default-toggle-icon-bar-bg:        transparent;
$navbar-default-toggle-border-color:       #000;


//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%);
$navbar-inverse-bg:                         #222;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%);

// Inverted navbar links
$navbar-inverse-link-color:                 lighten($gray-light, 15%);
$navbar-inverse-link-hover-color:           #fff;
$navbar-inverse-link-hover-bg:              transparent;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-disabled-color:        #444;
$navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color:          #fff;
$navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            #333;
$navbar-inverse-toggle-icon-bar-bg:         #fff;
$navbar-inverse-toggle-border-color:        #333;


//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding:                          10px 15px;
$nav-link-hover-bg:                         $teal;

$nav-disabled-link-color:                   $gray-light;
$nav-disabled-link-hover-color:             $gray-light;

//== Tabs
$nav-tabs-border-color:                     $gray-light;

$nav-tabs-link-hover-border-color:          $teal;

$nav-tabs-active-link-hover-bg:             $teal;
$nav-tabs-active-link-hover-color:          $gray;
$nav-tabs-active-link-hover-border-color:   #ddd;

$nav-tabs-justified-link-border-color:            #ddd;
$nav-tabs-justified-active-link-border-color:     $body-bg;

//== Pills
$nav-pills-border-radius:                   $border-radius-base;
$nav-pills-active-link-hover-bg:            $teal;
$nav-pills-active-link-hover-color:         #fff;


//== Pagination
//
//##

$pagination-color:                     $link-color;
$pagination-bg:                        #fff;
$pagination-border:                    #ddd;

$pagination-hover-color:               $link-hover-color;
$pagination-hover-bg:                  $gray-lighter;
$pagination-hover-border:              #ddd;

$pagination-active-color:              #fff;
$pagination-active-bg:                 $brand-primary;
$pagination-active-border:             $brand-primary;

$pagination-disabled-color:            $gray-light;
$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           #ddd;


//== Pager
//
//##

$pager-bg:                             $pagination-bg;
$pager-border:                         $pagination-border;
$pager-border-radius:                  15px;

$pager-hover-bg:                       $pagination-hover-bg;

$pager-active-bg:                      $pagination-active-bg;
$pager-active-color:                   $pagination-active-color;

$pager-disabled-color:                 $pagination-disabled-color;


//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width:           200px;
//** Tooltip text color
$tooltip-color:               #fff;
//** Tooltip background color
$tooltip-bg:                  #000;
$tooltip-opacity:             .9;

//** Tooltip arrow width
$tooltip-arrow-width:         5px;
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg;

