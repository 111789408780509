#map_radius {
  padding-right: 0;
}

#haendlersuche {
  padding-top: 25px;

  .searchform {
    background: url('/design/img/stageline/map-search-bg.jpg') no-repeat;
    background-size: cover;
    overflow: hidden;

    margin-bottom: 20px;

    h2 {
      color: #fff;
      font-size: 20px;
    }

    form {
      margin: 0;
    }

    #map_productcategory {
      @media (max-width: $screen-xs-max) {
        margin-top: 15px;
      }
    }

  }

  .results {
    background: #fff;
    padding: 25px;
    padding-bottom: 10px;

    h2 {
      font-size: 20px;
    }

    .item {
      display: block;
      background: #eee;
      padding: 15px;;
      min-height: 92px;
      margin-bottom: 15px;
      color: #000;

      &:hover {
        background: #ddd;
        text-decoration: none;
      }

      .pin {
        width: 26px;
        float: left;
      }
      .haendler {
        display: block;
        margin-left: 40px;
      }
    }

  }

  .display {
    margin-top: 25px;

    @include clearfix();
    position: relative;

    .map-container {
      height: 450px;
    }

    .map {
      height: 100%;
      width: 100%;
    }

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
      background: rgba(102, 102, 102, 0.88);
      color: #fff;

      @media (max-width: $screen-xs-max) {
        position: relative;
        bottom: auto;
        right: auto;
        width: 100%;
        background: #666;
      }
    }

    .store {
      padding: 25px;

      .about {
        margin-bottom: 25px;
      }

      .name {
        font-weight: 700;
        color: #fff;
        margin-top: 0;
        margin-bottom: 20px;
      }

      .address {
        .pin {
          width: 26px;
          float: left;
        }
        address {
          display: block;
          margin-left: 40px;
        }
      }

      .mobile-contact {
        padding: 10px 0;
        a { font-size: 14px; overflow: hidden; text-decoration: none }
        a:hover { color: #fff; }
      }

    }

    @media (max-width: $screen-xs-max) {
      .results, .store {
        padding: 13px;
      }
      .store {
        .btn {
          width: 100%;
          height: 45px;
          line-height: 30px;
        }
        .link-phone {
          margin-bottom: 22px !important;
        }
        .address {
          .pin {
            display: none
          }
          address {
            margin-left: 0
          }
        }
      }
    }

  }
  &.such-layout-1 {

    .display .overlay {
      display: block;
      position: relative;
      height: auto;
      width: 100%;
      background: rgb(238, 238, 238);
      border-bottom: 8px solid #3BC79F;
    }
  }
}
