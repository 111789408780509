


body {
  background: #E4E4E4;
  font-family: 'CeraPRO-Regular', 'Montserrat', 'Avenir', sans-serif;
  font-size: 15px;
  line-height: 1.45;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 15px;
  margin-top: 0;
}

h1 , .h1 {
  font-size: 35px;
}
h3, .h3 {
  font-size: 20px;
}
h4, .h4, h5, .h5, h6, .h6 {
  font-size: 15px;
}



.fce-header.shell {
  padding-bottom: 0;
}

.fce-textmedia {
  padding-top: 0;
}


.teal { color: $teal; }
.black { color: $black; }
.black80 { color: $black80; }
.black60 { color: $black60; }
.black40 { color: $black40; }
.black20 { color: $black20; }


.tealbg { background-color: $teal; }
.blackbg { background-color: $black; }
.black80bg { background-color: $black80; }
.black60bg { background-color: $black60; }
.black40bg { background-color: $black40; }
.black20bg { background-color: $black20; }

.header {
  padding: 25px;
  &.block {
    .text {
      max-width: 710px;
    }
  }
  @media (max-width: $screen-xs-max) {
    padding: 13px;
  }
}

.wrapper, .shell, .block-container {
  padding: 0 25px;
  @media (max-width: $screen-xs-max) {
    padding: 0 13px;
  }
}



.btn {
  text-transform: uppercase;
  border-width: 2px;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus {
  background: $teal;
  border-color: $teal;
  color: #fff;
  box-shadow: none;
}

.btn-primary:hover {
  background: transparent;
  border-color: $teal;
  color: $teal;
}

.btn-default,
.btn-default:focus,
.btn-default:active,
.btn-default:active:focus {
  background: #ccc;
  border-color: #ccc;
  color: #000;
  box-shadow: none;
}

.btn-default:hover {
  background: transparent;
  border-color: #ccc;
  color: #000;
}


a {
  text-decoration: underline;

  .img-idle {
    display: inline-block;
  }
  .img-over {
    display: none;
  }
}
a:hover {
  .img-idle {
    display: none;
  }
  .img-over {
    display: inline-block;
  }
}


.caret {
  top: 1px;
  border: none;
  width: 16px;
  height: 16px;
  font-size: 12px;
  position: relative;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: "\e259";
  }
}

.contact-table {
  padding: 25px;
  @media (max-width: $screen-xs-max) {
    padding: 13px;
  }

  span {
    color: #7c7c7c;
  }

  a {
    color: #000;
  }

  .row {
    margin-bottom: 25px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 35px;
    }
  }

  .name {
    font-weight: 400 !important;
    text-transform: none;
    font-size: $font-size-base;
    margin: 0;
  }

  .country {
    text-transform: uppercase;
  }
}

.modal-body-container p {
  max-width: 610px;
}

a.download {
  color: #000;
  display: inline-block;
  padding: 15px 15px 15px 0;
  text-decoration: none;
  &:before {
    background: #00A882 none repeat scroll 0 0;
    color: #fff;
    content: "\e025";
    display: inline-block;
    font-family: "Glyphicons Halflings";
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    text-align: center;
    width: 30px;
  }
}

.breadcrumb a, .breadcrumb span {
  vertical-align: middle !important;
}


#cookiebar {
  top: auto;
  top: initial;
  bottom: 0;
}

input[type=radio], input[type=checkbox] {
  display:none;
}

input[type=radio] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align:middle;
  margin-right: 7px;
  color: $brand-primary;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
}
input[type=checkbox] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align:middle;
  margin-right: 7px;
  color: $brand-primary;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
}

input[type=radio]:checked + label:before {
  content: "\2022"; /* Bullet */
  color: $brand-primary;
  font-size:1.8em;
  text-align:center;
  line-height:19px;
}
input[type=checkbox]:checked + label:before {
  content: "\2714"; /* Tick */
  color: $brand-primary;
  text-align:center;
  line-height: 27px;
}

input[type=radio] + label,
input[type=checkbox] + label {
  padding-left: 0;
}
