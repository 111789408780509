.ft {

  // First Bar
  // ------------------------------------------------

  .ft-links {
    padding: 20px;
    background: #333333;
    color: #fff;
    text-align: right; // for social

    .ft-links-bar {
      a {
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
      }
      .pages {
        padding-top: 3px;
        display: inline-block;
        float: left;
      }
      .page-link {
        padding-right: 11px;
      }
    }

    .ft-social {
      white-space: nowrap;
      a {
        padding-left: 5px;
      }
    }
  }

  // Second Bar
  // ------------------------------------------------

  .ft-details {
    padding: 20px;
    background: #5E5E5E;
    color: #fff;

    display: flex;
    flex-flow: row;

    .ft-left {
      width: 60%;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
    .ft-right {
      width: 40%;
    }

    .ft-trademark {
      display: flex;
      flex-flow: row;
      width: 460px;

      .ft-trademark-of {
        display: flex;
        flex-flow: column;
        justify-content: center;
        line-height: 1;
      }
      .ft-trademark-img {
        padding-left: 12px;
        img {
          max-width: 200px;
        }
      }
    }
    .ft-related {
      text-align: right;
      font-size: 13px;
      a { color: #fff; }
    }
  }
}


// Mobile Footer
// ------------------------------------------------

@media (max-width: $screen-xs-max) {
  .ft {
    .ft-links {
      text-align: left;
      padding-bottom: 0;

      .ft-links-bar {
        .page-link {
          padding-right: 0;
          display: block;
        }
        .pages {
          padding-top: 0;
          display: block;
          float: none;
        }
      }
      .ft-social {
        text-align: center;
        background: rgb(64, 64, 64);
        padding: 12px 0 14px;
        margin: 20px -30px 0;
        a {
          padding-right: 5px;
        }
      }
    }
    .ft-details {
      flex-flow: row wrap;

      .ft-left {
        width: 100%;
      }
      .ft-right {
        width: 100%;
      }

      .ft-trademark {
        margin: 0 auto;
      }
      .ft-related {
        padding-top: 20px;
        text-align: center;
      }
    }
  }
}


// Trademark Auto-Wrap
// ------------------------------------------------

@media (max-width: 470px) {
  .ft {
    .ft-details {
      .ft-trademark {
        width: 100%;
        display: block;
        text-align: center;
        .ft-trademark-of {
          padding-bottom: 10px;
        }
        .ft-trademark-img img {
          display: inline;
        }
      }
    }
  }
}