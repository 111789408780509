
.meta {
  color: #aaa
}

.visible-mobile {
  display: none;
}
.visible-mobile-inline {
  display: none;
}
.visible-mobile-inline-block {
  display: none;
}

@media (max-width: $grid-float-breakpoint-max) {
  .visible-mobile {
    display: block;
  }
  .visible-mobile-inline {
    display: inline;
  }
  .visible-mobile-inline-block {
    display: inline-block;
  }
  .hidden-mobile {
    display: none;
  }
}

.modal-close {
  background: $teal;
  color: #fff;
  float: right;
  height: 37px;
  width: 37px;
  line-height: 30px;
  border: 0;
  margin-top: -14px;
  margin-right: -15px;
}

.modal .content {
  h2 {
    margin-bottom: 35px;
  }
  hr {
    margin-top: 30px;
    margin-left: -40px;
    margin-right: -40px;
  }
}


.breadcrumb {
  background: #EDEDED;
  padding: 10px 25px;
  margin-bottom: 0;
  z-index: 600;
  position: relative;

  @media (max-width: $screen-xs-max) {
    padding: 10px 13px;
  }
}

.breadcrumb>li {
  line-height: 18px;
  @media (max-width: $screen-xs-max) {
    font-size: 12px;
  }
}
.breadcrumb>li+li:before {
  font-family: 'Glyphicons Halflings';
  content: "\e258";
  padding: 0 5px;
  color: #000;
  position: relative;
  top: 2px;
}
.breadcrumb a, .breadcrumb span {
  display: inline-block;
  vertical-align: text-bottom;
}
.breadcrumb a {
  color: #000;

}
.breadcrumb>.active {
  color: $teal;
}
.block {
  margin-bottom: 13px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 25px;
  }
}

.disclaimer {
  margin-top: 15px;
  @include clearfix();

  .star {
    float: left;
    width: 10px;
  }
  .text {
    font-size: 12px;
    margin-left: 10px;
  }
}

.banner {
  max-height: 480px;
  overflow: hidden;
  position: relative;
  img {
    min-width: 100%;
    max-width: 100%;
  }
  .watermark {
    top: 0;
    z-index: 1;
    position: absolute;
    display: none;
  }
  .caption {
    z-index: 1;
    position: absolute;
    top: 40%;
    left: 5%;
    font-size: 35px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    margin: 0;

    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      font-size: 25px;
    }
  }
}

.banner-products {
  max-height: 375px;

  .caption {
    top: auto;
    bottom: 8%;
    left: 3%;
  }
}

.banner-category {
  max-height: 300px;
  .caption {
    top: auto;
    bottom: 8%;
    left: 3%;
  }
}

.banner-productgrid {
  height: 150px;
}



.corner {
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 50%;
  position: absolute;
  top: 4%;
  right: 4%;
  background: #000;
  text-align: center;
  color: #fff;
}

.corner-new {
  background: $teal;
}

.corner-sale {
  background: #FF452F;
}

@media (max-width: $screen-xs-max) {
  .corner {
    top: 2%;
    right: 2%;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 12px;
  }
}