

// kontakt bubble

#contact_flyout {

  position: fixed;
  top: 200px;
  right: 0px;
  @media (max-width: $screen-xs-max) {
    top: 110px;
  }
  transition: right 300ms ease-in-out;

  height: 300px;
  width: 300px;

  border-radius: 50%;
  background: $teal;
  z-index: 1000;

  color: #fff;
  @include box-shadow(-1px 1px 4px 1px rgba(0, 0, 0, 0.2));

  .contact-flyout-wrap {
    position: relative;
    overflow: hidden;
  }

  .contact-title {
    color: #fff;
    text-transform: uppercase;
  }

  .caption-vertical {
    cursor: pointer;
    transform: rotate(270deg);
    transform-origin: left center 0;
    position: relative;
    top: 175px;
    left: 18px;
    transition: opacity 300ms ease-in-out;
  }

  .caption-action {
    text-align: center;
    margin-top: 15px;
    img {
      width: 14px;
    }
  }
  .caption-horizontal {
    padding-top: 15px;
    text-align: center;
    font-size: 18px;
  }

  .contact-buttons {
    padding: 0px 40px;
    padding-top: 30px;

    .contact-item {
      margin-bottom: 15px;
    }

    .btn {
      text-decoration: none;
      width: 100%;
      color: #fff;
      border-color: #fff !important;
      text-transform: none;
    }
  }

  .caption-vertical {
    opacity: 0;
  }

  &.closed {
    right: -260px;
    .caption-vertical {
      opacity: 1;
    }
  }

}
