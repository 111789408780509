$social-article-height: 390px;
$social-footer-height: 60px;

// Artikel partial
// ------------------------------------------------------------------------------------------------------------

.socialmedia-article {
  background-color: #edeeef;
  color: #3c3d3e;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  position: relative;
  height: $social-article-height;
  //transition: background-color 300ms;

  img, video {
    max-width: 100%;
    min-width: 100%;
  }
  video {
    object-fit: cover;
  }

  &.no-text .socialmedia-media {
    overflow: hidden;
    max-height: $social-article-height - $social-footer-height;
  }
  &.no-text .embed-responsive-16by9 {
    height: $social-article-height - $social-footer-height;
  }

  &:hover {
    //background-color: rgb(255, 255, 255);
  }
}

.socialmedia-image-item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.socialmedia-image-item-top {
  background-position: top center;
}

.socialmedia-playbutton {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;

  button {
    border-radius: 50%;
    background-image: url(//static.xx.fbcdn.net/rsrc.php/v3/yh/r/jSigk3Q_Pku.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 0 0;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    cursor: pointer;
    height: 80px;
    width: 80px;
    padding: 0;
    margin: 0 auto;
  }

  &:hover button {
    background-color: rgba(20, 22, 26, .7);
  }
}

.socialmedia-media .playing .socialmedia-playbutton {
  display: none;
}

.socialmedia-media {
  background: #333;
  .carousel-indicators {
    bottom: -6px;
  }
}

.socialmedia-media-brand {
  .socialmedia-image-item {
    background-size: 80px;
    background-position: center center;
  }
}

.socialmedia-content {
  padding: 10px;
  font-size: 14px;
}

.sociallink-wwwtag {
  word-break: break-all;
}

.socialmedia-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: $social-footer-height;

  a {
    background-color: rgb(247, 247, 247);
    //border-top: 1px solid rgb(255, 255, 255);
    padding: 10px;
    display: block;
    color: $text-color;
    text-decoration: none;
    &:hover {
      //border-top-color: $teal;
      color: $text-color;
    }
  }
}

.socialmedia-meta {
  display: inline-block;
  line-height: 1.2;
  font-size: 12px;
  margin-top: 7px;

  .socialmedia-source {
    display: block;
  }
  .socialmedia-date {
    display: inline-block;
    padding-bottom: 1px;
    border-bottom: 1px dashed rgb(179, 179, 179);
  }

}

.socialmedia-brand-icon {
  display: inline-block;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 45px;
  height: 40px;
  background-color: #fff;
  margin-right: 8px;
  float: left;
  border: 1px solid rgb(226, 226, 226);
}

.socialmedia-icon {
  display: inline-block;
  border-radius: 50%;
  width: 31px;
  height: 30px;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
  float: right;
  margin-top: 5px;
  margin-right: 3px;
  font-size: 15px;
  line-height: 1;
  padding: 7px 9px;
}

.socialmedia-article.source-news .socialmedia-icon {
  background: #35c8a0;
  color: #fff;
  padding-left: 10px;
}
.socialmedia-article.source-instagram .socialmedia-icon {
  background: #f32d9a;
  color: #fff;
}
.socialmedia-article.source-facebook .socialmedia-icon {
  background: #3c6091;
  color: #fff;
  padding-left: 10px;
}
.socialmedia-article.source-youtube .socialmedia-icon {
  background: #ca262b;
  color: #fff;
  padding-left: 8px;
}
.socialmedia-article.source-twitter .socialmedia-icon {
  background: #2e7ebf;
  color: #fff;
}



// Seite "inspiration"
// ------------------------------------------------------------------------------------------------------------

.socialmedia {
  min-height: calc(100vh - 80px);
  background-color: #000;
  color: #fff;

  background-position: center top;
  background-image: url('../../img/stageline/socialmedia-header.jpg');
  background-repeat: no-repeat;
}

.socialmedia-header {
  min-height: 635px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  text-align: center;
  padding: 0 40px;
  padding-top: 480px;

  .socialmedia-title {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .socialmedia-tagline {
    font-size: 14px;
    color: #777;
    margin-bottom: 15px;
  }
  .socialmedia-intro {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .socialmedia {
    background-position: center -200px;
  }
  .socialmedia-header {
    min-height: 400px;
    padding: 0 15px;
    padding-top: 280px;
    .socialmedia-intro {
      padding: 0;
      text-align: left;
    }
  }
}

.socialmedia-grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: 50px;
  margin-right: 10px;
  margin-left: 10px;

  .socialmedia-article {
    width: calc(25% - 20px);

    @media (max-width: 1199px) {
      width: calc(33.333% - 20px);
    }
    @media (max-width: 900px) {
      width: calc(50% - 20px);
    }
    @media (max-width: 565px) {
      width: 100%;
    }
  }
}

.socialmedia-more {
  text-align: center;
  padding: 25px 20px;
}


// Seite "produktdetail"
// ------------------------------------------------------------------------------------------------------------

#product .about .about-content .socialmedia-panel {
  background: rgb(220, 220, 220);
  padding: 30px 40px 10px;
  .h3 {
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .socialmedia-grid {
    padding: 0;
    margin: 0;

    .socialmedia-footer a {
      height: 100%;
    }
  }

  @media (max-width: 767px) {
    padding: 30px 15px 10px;
    background: #333;
    .h3 {
      display: none;
    }
  }
}