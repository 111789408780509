.page {

  padding: 25px;
  padding-top: 40px;

  @media (max-width: $screen-xs-max) {
    padding: 13px;
    padding-top: 25px;
  }

  .title {
    font-size: 25px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .text {

  }

}