
// mini navigation (>break only)

#mininav {
  min-height: 50px;
  margin: 0;
  margin-top: -1px;
  margin-right: -2px;

  color: #aaa;
  a { color: #aaa; }

  .item {
    padding-left: 15px;
  }

  .lang .dropdown-menu {
    margin-right: 127px;
    margin-top: -15px;
    min-width: 100px;
    a { text-decoration: none; }
  }

  .phone {
    font-weight: bold;
    color: $brand-primary;
  }

  display: none;
  @media (min-width: $grid-float-breakpoint) {
    display: block;
  }

  .toggle-favorites {
    background: #fff;
    color: #aaaaac;
    padding: 10px;
    padding-top: 12px;
    display: inline-block;
    text-decoration: none;
    margin-left: 20px;

    small {
      text-transform: capitalize;
      font-size: 100%;
    }

    .badge {
      background: $teal;
      color: #fff;
      position: absolute;
      z-index: 10;
      margin-left: -17px;
      margin-top: -8px;
      font-weight: normal;
      padding: 4px 6px;
    }

    &:hover {
      background: $teal;
      color: #fff;
      .badge {
        background: #fff;
        color: $teal;
      }
    }
  }

}

// main navigation


.favorites-empty .badge {
  display: none;
}

#header, #mainnav {
  z-index: 9100 !important;
}
#mininav {
  z-index: 9900 !important;
}

#app #header,
body.homepage.img #app #header,
body.catalog.img #app #header {
  border-bottom: 4px solid $teal !important;
  box-shadow: none !important;
}

#mainnav {
  margin-bottom: 0;
  min-height: 45px;
  line-height: 20px;
  border: 0;

  @media (min-width: $grid-float-breakpoint) {
    .nav {
      float: right !important;
      margin-right: -12px;
    }
  }

  .navbar-brand {
    padding: 0;
    padding-left: 25px;
    margin-top: -15px;
    > img {
      height: 50px;
      transition: height 200ms ease-out;
    }
  }

  .navbar-toggle {
    background: transparent;
    margin: 0;
    font-size: 25px;
    padding: 15px;
    padding-bottom: 19px;
    border: 0;
    color: #000;
  }

  .menu-close {
    padding: 0 1px;
  }

  .nav > li > a {
    padding: 10px 0 3px 0;
    margin: 0 15px;
    display: inline-block;
    font-size: 16px;
    letter-spacing: 0.1em;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    color: $black;
    border: 0;
    border-bottom: 2px solid transparent;
  }

  .nav > li > a:hover,
  .nav > li > a:focus {
    color: $black;
    background-color: transparent;
    box-shadow: none;
    border-bottom: 2px solid $black;
  }

  .nav > li.active > a {
    color: $teal;
    background-color: transparent;
    box-shadow: none;
    border-bottom: 2px solid $teal;
  }

  .nav > li.search > a {
    overflow: hidden;
    line-height: 17px;
    font-size: 17px;
    border: 0;
  }
  .nav > li.search.active > a {
    box-shadow: none;
    color: $teal !important;
  }
  .nav .open > a {
    background-color: transparent;
  }

  .dropdown-menu {
    padding: 0;
    border: 0;
    padding-top: 10px;
    z-index: 9999;
  }
  .dropdown-menu>li {
    border-bottom: 1px solid $black20;
  }
  .dropdown-menu>li:last-of-type {
    border-bottom: 0;
  }
  .dropdown-menu>li>a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    padding: 12px 20px;
    color: #000;
    font-weight: 700;
  }
  .dropdown-menu>li>a:hover {
    background: #fff;
    color: $teal !important;
  }

  span.caret {
    margin-left: 0;
    margin-top: 3px;
    float: right;
  }
  .navbar-collapse {
    border-top: 0;
  }
  .navbar-nav {
    margin: 0 -12px;
  }

  .navbar-header {
    .toggle-search, .toggle-favorites {
      display: none;
    }
  }

  .toggle-favorites {
    background: #fff;
    color: $teal;
    padding: 10px;
    padding-top: 12px;
    display: inline-block;
    text-decoration: none;

    small {
      text-transform: capitalize;
      font-weight: normal;
      font-size: 12px;
    }

    .badge {
      background: $teal;
      color: #fff;
      position: absolute;
      z-index: 10;
      margin-left: -17px;
      margin-top: -8px;
      font-weight: normal;
      padding: 4px 6px;
    }

    &:hover {
      background: $teal;
      color: #fff;
      .badge {
        background: #fff;
        color: $teal;
      }
    }
  }



  .dropdown-toogle {
    display: none;
  }

  @media (max-width: $grid-float-breakpoint-max) {

    li {
      min-height: 50px;
      a, a:hover, a:focus {
        border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
        border-bottom-color: transparent !important;
        padding: 17px 15px 12px 15px !important;
        display: block !important;
        margin: 0 !important;
      }
      a[href^="tel"] {
        padding: 10px 0 !important;
      }
    }
    li.active a {
      border-bottom: 0 !important;
    }
    .dropdown-menu {
      padding-top: 0;
    }

    .nav .open > a, .nav .open > a:hover {
      color: #fff;
      background-color: $teal;
    }

    .dropdown-toogle {
      position: absolute;
      right: 0;
      top: 0;
      outline: 0;
      width: 48px;
      height: 50px;
      float: right;
      background: #fff;
      border: 0;
      padding: 0;
      border-left: 1px solid rgb(204, 204, 204);
      border-bottom: 1px solid rgb(204, 204, 204);
      display: flex;
      justify-content: center;
      align-items: center;
      .caret {
        color: rgb(48, 200, 158);
        float: none;
      }
    }
    .open .dropdown-toogle {
      width: 49px;
      background-color: rgb(48, 200, 158);
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;

      .caret {
        color: #fff;
        transform: rotate(180deg);
        margin-top: -3px;
      }
    }

    .nav .open > a {
      color: #fff;
      background-color: rgb(48, 200, 158);
      border-bottom: 1px solid #fff;
    }

    .toggle-favorites .badge {
      margin-left: -8px;
    }

    .navbar-brand {
      padding-left: 23px;
      margin-top: 10px;
      height: auto;
      img {
        height: 35px;
      }
    }

    .caret {
      display: block !important;
    }

    .search, .merkliste {
      display: none !important;
    }

    .navbar-header {
      @include box-shadow(0 5px 8px -1px rgba(0,0,0,.2));

      .toggle-search, .toggle-favorites {
        display: block;
        position: relative;
        float: right;
        display: block;
        padding: 15px;
      }

      .toggle-favorites {
        margin-right: 4px;
      }

      .toggle-search {
        color: #000;
        font-size: 20px;
        margin-right: 3px;
      }
    }

    .nav>li {
      border-bottom: 1px solid $black20;
    }
    .nav>li.black20bg {
      border-bottom-color: #fff;
    }

    .nav>li>a {
      padding-left: 25px;
    }
    .dropdown-menu > li {
      padding-left: 15px;
    }
    li.visible-xs {
      display: block !important;
    }

  }

  @media (min-width: $grid-float-breakpoint) {
    li.dropdown:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }

}


@media (min-width: $grid-float-breakpoint) {
  // Catalog classes
  // ----------------------------------------------------------------
  .catalog-audio #mainnav .nav > li > a[href*="/audio/"],
  .catalog-audio #mainnav .nav > li > a[href*="/audio-tools/"],
  .catalog-light #mainnav .nav > li > a[href*="/licht/"],
  .catalog-light #mainnav .nav > li > a[href*="/light/"],
  .catalog-mixers #mainnav .nav > li > a[href*="/mixers/"],
  .catalog-mixers #mainnav .nav > li > a[href*="/mischpulte/"],
  .catalog-microphones #mainnav .nav > li > a[href*="/mikrofone/"],
  .catalog-microphones #mainnav .nav > li > a[href*="/microphones/"] {
    color: $teal !important;
    border-bottom: 2px solid $teal !important;

    &:hover {
      border-bottom-color: black !important;
    }
  }
}

// Helper classes
// ----------------------------------------------------------------

.visible-menu-open {
  display: none;
}
.visible-menu-closed {
  display: block;
}
body.menu-visible {
  .visible-menu-open {
    display: block;
  }
  .visible-menu-closed {
    display: none;
  }
  #mainnav .navbar-toggle {
    background: $teal;
    color: #fff;
  }
}

body.search-visible {
  @media (max-width: $grid-float-breakpoint-max) {
    #mainnav .navbar-header .toggle-search {
      color: $teal;
    }
  }
}



// fixed header
@media (max-width: $grid-float-breakpoint-max) {
  body {
    padding-top: 54px;
  }
  #app {
    position: relative;
    max-width: 100%;
    #header {
      background: transparent;
      position: fixed !important;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1010;

      .navbar {
        background: #fff;
      }
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  body.scrolling {
    padding-top: 99px;
    #mininav {
      display: none;
    }
    #mainnav {
      .navbar-brand {
        margin-top: 9px;
        height: 30px;
        >img {
          height: 30px;
        }
      }
    }
    #app {
      position: relative;
      max-width: 100%;
      #header {
        background: transparent;
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1010;
        .navbar {
          background: #fff;
        }
      }
    }
    @media (min-width: 1298px) {
      #app #header {
        width: 1280px;
      }
    }
  }
}

