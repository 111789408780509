

#product {

  .needhelp {
    background: #fff;

    display: none;
    @media (max-width: $screen-xs-max) {
      padding: 13px;
      display: block;
      margin-bottom: 25px;

      img {
        margin: 0 auto;
      }
    }
  }

  .drift-zoom-pane {
    border: 1px solid $gray-lighter;
    margin-left: 14px;
    margin-right: 15px;
  }


  .overview {
    padding: 25px 50px;
    @media (max-width: $screen-xs-max) {
      padding: 25px 15px;
    }
    position: relative;

    .social {
      position: absolute;
      right: 20px;
      top: 10px;
      z-index: 70;

      img {
        margin-bottom: 5px;
      }
    }

    .corner {
      right: initial;
      left: 21px;
      top: 10px;
      z-index: 700;
    }

    .image {
      .viewer {
        text-align: center;
        border: 1px solid #eee;
        background: #fff;

        img {
          margin: 0 auto;
          display: inline-block;

          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }

        .item {
          padding: 40px;
          height: 360px;
        }

        .carousel-indicators {
          bottom: -60px;
          li {
            border-color: #DEDEDE;
            background: #DEDEDE;
          }
          .active {
            background: #8D8D8D;
          }


          @media (max-width: $screen-sm-max) {
            bottom: -8px;
          }

          @media (min-width: $screen-sm-min) {
            display: none;
          }
        }

      }

      .images {
        padding-top: 15px;
        @include clearfix();
      }

      .badges {
        position: relative;
        padding-top: 10px;
      }
    }

    .thumbnails {
      position: relative;
      .thumbnail {
        float: left;
        cursor: pointer;
        border-color: #eee;
        background: #fff;
        padding: 5px;
        display: block;

        margin-right: 9px;
        height: 72px;
        width: 72px;

        &:last-of-type {
          margin-right: 0;
        }

        img {
          max-height: 100%;
          position: relative;
          top: 50%;
          transform: translateY(-50%)
        }

        .glyphicon-plus {
          margin-top: 21px;
          margin-left: 22px;
        }
      }
      .active {
        border-color: $brand-primary;
      }
    }

    .title {
      font-weight: 700;
      font-size: 40px;
      margin: 0;



      @media (max-width: $screen-sm-max) {
       margin-top: 25px;
      }
    }

    .meta {
      margin-bottom: 50px;
    }

    .desc {
      a {
        font-weight: 700;
      }
    }

    .tagline {
      font-weight: 700;
      margin-bottom: 20px;
      font-size: 15px;
      text-transform: none;
    }

    .price {
      text-align: right;
      font-size: 17px;
      margin-bottom:15px;
    }

    .buttons {
      text-align: right;

      .btn {
        text-decoration: none;
      }

      .btn-inverse {
        border-width: 2px;
      }
    }
  }



  .about {
    padding-top: 25px;

    .tabs {
      a {
        display: block;
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 17px;
        font-weight: bold;
        background: $teal;
        color: #fff;
        text-decoration: none;
        outline: 0;
      }
      a.active {
        background: #eee;
        color: #000;
      }
      a:hover {
        text-decoration: none;
      }
      a:hover, a:active, a:focus {
        outline: 0;
      }

      .row {
        margin-left: -1px;
        margin-right: -1px;
      }
      .row > div {
        padding-left: 1px;
        padding-right: 1px;
      }
    }

    .tabs.sticky {
      position: fixed;
      left: 0;
      right: 0;
      top: 49px;
      background: #fff;
      box-shadow: 0 3px 6px -2px rgba(0,0,0,.5);
      z-index: 80;
      max-width: 1280px;
      margin: 0 auto;
    }

    .about-content {

      .about-panel {
        background: #eee;
        padding: 25px 50px;
        margin-bottom: 2px;

        @media (max-width: $screen-xs-max) {
          padding: 13px;
        }

        h3, .h3 {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 35px;
        }
      }

      .accordion-tab {
        display: none;
      }

      @media (max-width: $screen-xs-max) {
        .accordion-tab {
          display: block;
          font-size: 17px;
          font-weight: bold;
          padding: 16px 13px;
          color: #fff;
          background: $teal;
          text-transform: uppercase;
          text-decoration: none;
          //font-weight: 700;
          cursor: pointer;
          margin-bottom: 1px;

          .caret {
            top: 5px;
          }
        }
        .accordion-tab:hover {
          text-decoration: none;
        }
        .accordion-tab.active {
          //background: #000;
        }
        .about-panel {
          display: none;
        }
        .about-panel.active {
          display: block;
        }
      }
      @media (min-width: $screen-sm-min) {
        .about-panel {
          display: block !important;
        }
      }
    }

    #related {
      background: #666666;
      color: #fff;

      .more {
        font-weight: bold;
      }

      .product-blocks-items {
        padding: 0;
        background: transparent;
      }
    }

    #techdetails {
      background: #fff;

      .h4 {
        font-size: 17px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 25px;
        margin-top: -5px;
      }

      table {
        width: 100%;
        margin-left: -25px;
        border-spacing: 25px 0;
        border-collapse: separate;

        @media (max-width: $screen-xs-max) {
          margin-left: 0px;
          border-spacing: 0px 0;
          margin-bottom: 25px;
        }
      }

      td, th {
        padding: 8px 2px;
        width: 50%;
      }

      thead {
        th {
          border-top: 2px solid #000;
          border-bottom: 2px solid #000;
        }
      }

      tbody {
        td {
          border-bottom: 1px solid #000;
          p{ margin-bottom: 0}
        }
      }

      tfoot {
        td {
          border-top: 1px solid #000;
        }
      }

      .bold {
        font-weight: bold;
      }

      .tab {
        padding-left: 15px;
      }


      ul.downloads {

        li {
          padding-bottom: 10px;
          line-height: 32px;
        }
        img {
          padding-right: 10px;
        }
        a {
          color: #000;
        }
        a:hover {
          text-decoration: none;
        }
      }


    }


    .services {
      background: #000 !important;
      color: #fff !important;

      .item {
        height: 320px;
        margin-top: -25px;
        margin-bottom: -25px;
        text-align: center;
        background: #222222;

        .item-icon {
          padding: 10px 0;
        }
        .contact {
          padding-top: 25px;
          line-height: 25px;

          a {
            color: #fff;
            text-decoration: underline;
          }
        }

        @media (max-width: $screen-xs-max) {
          margin: 0 -15px 15px -15px;
          padding: 0;
          height: 320px;

          .contact {
            padding-top: 0;
          }
        }

      }

      @media (min-width: $screen-xs-max) {
        .item:hover {
          margin-top: -35px;
          padding-bottom: 60px;
          background: $teal;
          color: #fff;
        }
      }
    }

  }

  .needhelp {
    padding-bottom: 15px;
    .buttons {
      text-align: center;
    }
    .buttons a {
      display: inline-block;
      line-height: 70px;
      height: 70px;
      width: 70px;
      background: $brand-primary;
      color: #fff;
      margin: 0 25px;
      text-align: center;
      border-radius: 50%;
      font-weight: bold;
    }
    .buttons img {
      width: 50px;
      height: 50px;
    }
  }

}


#product.brand-monacor {
  #imageviewer:after {
    position: absolute;
    display: block;
    width: 70px;
    height: 43px;
    z-index: 2;
    background: url('/design/img/monacor.png') no-repeat top left;
    background-size: contain;
    content: " ";
    bottom: 10px;
    right: 10px;
  }
}


#merkliste .item-monacor {
  .thumbnail {
    position: relative;
  }
  .thumbnail:after {
    position: absolute;
    display: block;
    width: 35px;
    height: 22px;
    z-index: 2;
    background: url('/design/img/monacor.png') no-repeat top left;
    background-size: contain;
    content: " ";
    bottom: 5px;
    right: 5px;
  }
}