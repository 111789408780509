#products {
  .textblock {
    padding: 25px;
    @media (max-width: $screen-xs-max) {
      padding: 13px;
    }
  }
  .watermark {
    opacity: 0.5;
  }
}

.filter {
  background: #fff;
  padding: 25px;
  @media (max-width: $screen-xs-max) {
    padding: 13px;
  }
  h3, .h3 {
    margin-bottom: 25px;
  }

  .h4 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .range-filter {
    margin-bottom: 15px;

    .slider {
      width: 100% !important;
    }
    .slider-handle {
      background-image: none;
      background-color: $teal;
      opacity: 1
    }
    .slider-track {
      background-image: none;
      background-color: #eee;
    }
    .slider-selection {
      background-image: none;
      background-color: #ccc;
    }
    .range-value {
      width: 30%;
      display: inline-block;
    }
    .range-values {
      padding-top: 20px;
      @include clearfix();//
      text-align: center;
      line-height: 30px;
    }
    .range-value-from {
      float: left;
    }
    .range-value-to {
      float: right;
    }
  }


  .checkbox-filter {
    li {
      padding-bottom: 10px;
    }

    label {
      font-weight: normal;
      cursor: pointer;
    }
  }


  input[type=radio], input[type=checkbox] {
    display:none;
  }

  input[type=radio] + label:before {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align:middle;
    margin-right: 15px;
    color: $brand-primary;
    background-color: #fff;
    border-radius: 0px;
    border: 1px solid #ccc;
  }
  input[type=checkbox] + label:before {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align:middle;
    margin-right: 15px;
    color: $brand-primary;
    background-color: #fff;
    border-radius: 0px;
    border: 1px solid #ccc;
  }

  input[type=radio]:checked + label:before {
    content: "\2022"; /* Bullet */
    color: $brand-primary;
    font-size:1.8em;
    text-align:center;
    line-height:19px;
  }
  input[type=checkbox]:checked + label:before {
    content:"\2714"; /* Tick */
    color: $brand-primary;
    text-align:center;
    line-height:21px;
  }
}


.product-blocks {

  .product-blocks-header {
    margin-bottom: 25px;

    .product-label {
      margin-left: -1px;
      padding: 8px 0;
      background: #666666;
      color: #fff;
      text-transform: uppercase;

      @media (max-width: $screen-xs-max) {
        font-size: 12px;
      }
    }

    .product-sort {
      background: rgb(102, 102, 102);
      text-align: right;
      margin-right: -25px;

      @media (max-width: $screen-xs-max) {
        margin-right: -13px;
      }

      .dropdown-toggle {
        color: #fff;
        @media (max-width: $screen-xs-max) {
          font-size: 12px;
        }
      }

      .dropdown-menu {
        right: 0;
        left: auto;

        a {
          min-height: initial !important;

          @media (max-width: $screen-xs-max) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .product-blocks-items {
    background: #666666;
    padding: 25px;
    padding-top: 0;

    @media (max-width: $screen-xs-max) {
      padding: 13px;
      padding-top: 0;
    }

    .listitem {
      .item {
        display: block;
        position: relative;
        background: #fff;
        margin-bottom: 5px;
        padding: 15px;
        text-decoration: none;
        color: #000;

        &:hover {
          color: #000;
        }
      }
      .title {
        font-weight: bold;
        color: $brand-primary;
        margin-top: 0;
        margin-bottom: 5px;

        @media (max-width: $screen-xs-max) {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      .meta {
        font-size: 13px;
        @media (max-width: $screen-xs-max) {
          font-size: 12px;
        }
      }

      .teaser {
        font-size: 13px;
        @media (max-width: $screen-xs-max) {
          font-size: 12px;
        }
      }
      .price {
        text-align: right;
        @media (max-width: $screen-xs-max) {
          font-size: 12px;
          padding-top: 10px;
        }
      }

      .image {
        height: 65px;
        text-align: center;

        @media (max-width: $screen-xs-max) {
          height: 140px;
        }

        img {
          display: inline-block;
          max-height: 100%;
        }
      }
    }


    .griditem {
      .item {
        margin-bottom: 35px;
        @media (max-width: $screen-xs-max) {
          margin-bottom: 20px;
        }
        @media (min-width: $screen-sm-min) {
          transition: transform 150ms ease-out;
          z-index: 1;
        }
        &:hover {
          @media (min-width: $screen-sm-min) {
            transform: scale(1.1);
            z-index: 100;
          }
        }
      }

      .item {
        min-height: 375px;
        display: block;
        position: relative;
        background: #fff;
        text-decoration: none;
        &.item-mini {
          min-height: 300px;
        }
      }

      .product-navigation {
        text-align: right;
        a {
          min-height: initial;
          background: transparent;
        }
      }

      .image {
        padding: 35px;
        padding-bottom: 0;
        @media (max-width: $screen-xs-max) {
          padding: 13px;
          padding-bottom: 0;
        }
      }

      .image img {
        max-width: 100%;
        max-height: 100%;
        height: auto !important;
        width: auto !important;
      }

      .link {
        opacity: 0;

        background: $teal;
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px;
        text-align: center;
        text-transform: uppercase;

        transition: opacity 250ms ease-out;
      }

      .details {
        padding: 25px;
        color: #000;
        text-decoration: none;

        @media (max-width: $screen-xs-max) {
          padding: 13px;
        }

        .title {
          font-weight: 700;
          margin: 0;
          font-size: 20px;
        }

        .meta, .teaser {
          font-size: 13px;
        }

        .previous {
          text-decoration: line-through;
          color: #ccc;
        }

        .price {
          position: absolute;
          bottom: 24px;
        }
      }
    }


    a:hover {
      text-decoration: none;
    }

    @media (min-width: $screen-sm-min) {
      a:hover .link {
        opacity: 1;
      }
    }

  }

}