#category {
  .textblock {
    padding: 25px;
    @media (max-width: $screen-xs-max) {
      padding: 13px;
    }
  }
}


.category-header {
  height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.category-header-image {
  height: 300px;
  background-repeat: no-repeat;
  background-position: left center;
  opacity: 0.3;
}
.category-header-slim {
  height: 150px;
  .category-header-image {
    height: 150px;

    @media (max-width: $screen-xs-max) {
      background-position: center center;
      background-size: cover;
    }
  }
}
.category-header-caption {
  position: absolute;
  bottom: 25px;
  left: 25px;
  margin: 0;
  font-weight: bold;
  color: #fff;

  @media (max-width: $screen-xs-max) {
    font-size: 20px;
  }
}




.caption-blocks {
  margin: 0 25px;

  .item {
    margin-bottom: 50px;
    a {
      display: block;
      position: relative;
    }
  }

  .image {
    min-height: 300px;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &.old-image {
      max-width: 70%;
      margin: 0 auto;
      background-size: contain;
    }
  }

  .caption {
    position: absolute;
    background: rgba(0,0,0,0.6);
    padding: 25px;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0
  }

  .item a:hover {
    .caption {
      background: $teal;
      color: #fff;
    }
  }

  @media (max-width: $screen-xs-max) {
    margin: 0 13px;
    .item {
      margin-bottom: 25px;
    }
    .caption {
      padding: 15px;
    }
  }
}

